<template>
  <!-- <div class="siting-analysis">sitingAnalysis</div> -->
  <div>
    <div v-if="brandId">
      <div class="wrap-view">
        <div class="view1">
          <h3 class="w-title-gradients">品牌入驻商圈偏好
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="bottom"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
              <div slot="content">商圈划分说明：<br />
                1、商圈能级类型（市级/区域）：商圈可辐射区域及消费者的范围等级，依据商圈可辐射区域及消费者的范围判断；<br />
                2、商圈发展类型（成熟/新兴）：商圈存在的时长级别；<br />
                成熟商圈：随着时间积累商业发展已相对完善的商圈；<br />
                新兴商圈：近年来伴随城市商业发展新形成的商圈。</div>
            </el-tooltip>
          </h3>
          <div class="chart-view">
            <!-- 按功能划分 -->
            <div
              class="chart-box"
              id="pieFunction"
              v-show="hasDataPieFunction"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieFunction"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <br>
              <span>暂无数据</span>
            </div>
            <!-- 按发展划分 -->
            <div
              class="chart-box"
              id="pieDevelop"
              v-show="hasDataPieDevelop"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieDevelop"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <br>
              <span>暂无数据</span>
            </div>
          </div>
        </div>
        <div class="view2">
          <h3 class="w-title-gradients">品牌入驻购物中心偏好</h3>
          <div class="chart-view">
            <!-- 档次偏好 -->
            <div
              class="chart-box"
              id="pieLevel"
              v-show="hasDataPieLevel"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieLevel"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <br>
              <span>暂无数据</span>
            </div>
            <!-- 体量偏好 -->
            <div
              class="chart-box"
              id="pieVolumes"
              v-show="hasDataPieVolumes"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieVolumes"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <br>
              <span>暂无数据</span>
            </div>
            <!-- 开业年限偏好 -->
            <div
              class="chart-box"
              id="pieYears"
              v-show="hasDataPieYears"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieYears"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <br>
              <span>暂无数据</span>
            </div>
            <!-- 楼层偏好 -->
            <div
              class="chart-box"
              id="pieFloors"
              v-show="hasDataPieFloors"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieFloors"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <br>
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </div>
      <div class="view3">
        <h3 class="w-title-gradients">品牌同楼层开店关联偏好 <span>(吻合该品牌偏好的业态将被黄色显示)</span> </h3>
        <div class="w-select-screen-border">
          <el-select
            popper-class="w-block-select-down  w-block-select-down-border"
            class="select-extra fr"
            v-model="volumesNum"
            placeholder="请选择"
            @change="getDataFloorFormat"
          >
            <el-option
              v-for="item in volumesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="clearfix"></div> -->
        <div class="simpleWrap mg-0-auto">
          <div class="fl cardbox charts-bar">
            <h3>{{storeObj.brandName}}/{{storeObj.floor}}关联业态TOP10</h3>
            <div
              class="chartsBar"
              id="barFormat"
              v-show="hasDataFormatTop10"
            ></div>
            <div
              class="tips-wrap"
              v-show="hasDataFormatTop10"
            >
              <p>本品牌在本项目同楼层关联业态与该品牌偏好重合度 {{leftPercentage}}%</p>
            </div>
            <div
              v-if="!hasDataFormatTop10"
              class="details-no-data"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
          <div class="fr cardbox">
            <h3>{{storeObj.brandName}}/{{storeObj.floor}}关联品牌TOP10</h3>
            <div
              class="brand-ranking-box"
              v-loading="top10Loading"
              background="none"
              element-loading-text="加载中..."
            >
              <div
                class="brand-ranking"
                v-if="brandTopList && brandTopList.length > 0"
              >
                <ul>
                  <li class="menu">
                    <span>排序</span>
                    <span>品牌名称</span>
                    <span>品牌业态</span>
                  </li>
                  <li
                    v-for="(item, index) in brandTopList"
                    :key="index"
                    :class="[item.brandType === 1 ? 'active' : '']"
                  >
                    <span>{{index + 1}}</span>
                    <!-- <span @click="toDetails(item.brandId, 0, item.gbid)">{{item.brandName}}</span> -->
                    <span>{{item.brandName}}</span>
                    <span>{{item.industry}}</span>
                  </li>
                </ul>
              </div>
              <div
                v-if="!hasDataFormatTop10"
                class="details-no-data"
              >
                <img
                  src="@/assets/images/no_list_data.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
              <div
                class="tips-wrap"
                v-if="brandTopList && brandTopList.length > 0"
              >
                <p>本品牌在本项目同楼层关联品牌与该品牌偏好重合度 {{rightPercentage}}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="no-data-panel details-no-data mt-20"
      v-else
    >
      <img
        src="@/assets/images/public/not-plandata.png"
        alt=""
      >
      <p>云端品牌库未收录此品牌，暂无数据</p>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
export default {
  name: 'sitingAnalysis',
  data () {
    return {
      isShowBrandDetail: false,
      isShowBrandContent: false,
      district: 0,
      subMenu: [],
      gbid: '',
      source: '',
      areaValue: '',
      params: {
        brand_id: '',
        gbid: '',
        source: '',
        cityID: '',
        cityShangYeLevel: '',
        provinceID: '',
        regionID: ''
      },
      pieText: {
        pieFunction: '', // 按功能划分商圈
        pieDevelop: '',
        pieLevel: '',
        pieVolumes: '',
        pieYears: '',
        pieFloors: ''
      },
      hasDataPieFunction: true,
      hasDataPieDevelop: true,
      hasDataPieLevel: true,
      hasDataPieVolumes: true,
      hasDataPieYears: true,
      hasDataPieFloors: true,
      hasDataFormatTop10: true,
      hasDataBrandTop10: true,
      volumesNum: 0,
      volumesOptions: [],
      brandTopList: [],
      top10Loading: true,
      hasDataStoreDistribution: true,
      hasDataCompeting: true,
      hasDataProductLine: true,
      storeAreaCharts: {},
      lineCompetitor: {},
      isShowMoreMenu: false,
      storeObj: {},
      colors: ['#FFB7F5', '#7F6DFF', '#90DCF2', '#3E8CFF', '#FFCE73', '#7fba7a', '#7F6DFF', '#90DCF2', '#3E8CFF'],
      floorId: '',
      brandPrefer: {},
      leftPercentage: 0,
      rightPercentage: 0
    }
  },
  props: {
    brandId: {
      type: Number
    },
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    storeOptions: {
      type: Array
    }
  },
  mounted () {
    this.storeObj = this.storeOptions.find((item) => {
      return item.storeCode === this.filtroStore
    })
    this.floorId = this.storeObj.floorId
    setTimeout(() => {
      this.getDataBrandEnterPrefer() // 品牌入驻偏好
      this.getDataVolumes() // 同楼层开店关联筛选项
      this.getDataFloorFormat() // 同楼层开店关联业态TOP10
    }, 500)
  },
  watch: {
    filtroProject (newValue, oldValue) {
      console.log('filtroProject', this.filtroProject)
    },
    filtroStore (newValue, oldValue) {
      console.log('filtroStore', this.filtroStore)
    }
  },
  methods: {
    getDataBrandEnterPrefer () {
      const params = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.post(api.brandEntryPreference, params).then(res => {
        if (res.data.code === 0) {
          this.brandPrefer = res.data.data
          this.getDataCityBrand()
          this.getDataPlaza()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataCityBrand () {
      const params = {
        brand_id: this.brandId
      }
      this.axios.post(api.getAnalysisOfCityFunctionBusinessDistrict, params).then(res => {
        if (res.data.code === 0 && res.data.data.length) {
          this.hasDataPieFunction = true
          const resData = res.data.data
          this.createChartsPie(this.pieHandleData(resData), 'pieFunction', 0)
        } else {
          this.hasDataPieFunction = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.post(api.getAnalysisOfCityExpandBusinessDistrict, params).then(res => {
        if (res.data.code === 0 && res.data.data.length) {
          this.hasDataPieDevelop = true
          const resData = res.data.data
          this.createChartsPie(this.pieHandleData(resData), 'pieDevelop', 1)
        } else {
          this.hasDataPieDevelop = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    pieHandleData (resData) {
      let maxItem = {
        name: resData[0].name,
        percents: resData[0].percents
      }
      const dataArr = resData.map(item => {
        const tempObj = {
          name: item.name,
          value: item.counts,
          percents: item.percents
        }
        if (maxItem.percents < item.percents) {
          maxItem = item
        }
        return tempObj
      })
      // return { dataArr, maxItem }
      return dataArr
    },
    createChartsPie (data, container, key) {
      console.log('data', data)
      const titleList = ['按功能划分', '按发展划分', '档次偏好', '体量偏好', '开业年限偏好', '楼层偏好']
      const graphicList = []
      if (this.brandPrefer.useBusiness) {
        graphicList[0] = '本项目处于' + this.brandPrefer.useBusiness
      }
      if (this.brandPrefer.developBusiness) {
        graphicList[1] = '本项目处于' + this.brandPrefer.developBusiness
      }
      if (this.brandPrefer.grade) {
        graphicList[2] = '本项目档次' + this.brandPrefer.grade
      }
      if (this.brandPrefer.mass) {
        graphicList[3] = '本项目体量' + this.brandPrefer.mass + '万㎡'
      }
      if (this.brandPrefer.openYears) {
        graphicList[4] = '本项目开业' + this.brandPrefer.openYears
      }
      if (this.brandPrefer.floor) {
        graphicList[5] = '在本项目处于' + this.brandPrefer.floor
      }
      const legendName = []
      for (let i = 0; i < data.length; i++) {
        const ele = data[i]
        legendName.push(ele.name)
      }
      const charts = echarts.init(document.getElementById(container))
      const option = {
        title: {
          zlevel: 0,
          text: titleList[key],
          top: '39%',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 16
          }
        },
        graphic: {
          type: 'text',
          zlevel: 0,
          top: '75%',
          left: 'center',
          style: {
            text: graphicList[key] ? graphicList[key] : '',
            fill: '#fff',
            fontSize: 16
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        legend: {
          // orient: 'vertical',
          x: 'center',
          bottom: 15,
          data: legendName,
          color: '#fff',
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: '#808191'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '65%'],
            center: ['50%', '42%'],
            avoidLabelOverlap: false,
            zlevel: 1,
            hoverOffset: 11,
            label: { //  饼图图形上的文本标签
              normal: { // normal 是图形在默认状态下的样式
                show: false,
                position: 'center',
                fontSize: 16,
                fontWeight: 'bold'
                // formatter: '{c}' // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容，
              }
            },
            itemStyle: {
              normal: {
                shadowBlur: 50,
                shadowColor: '#444'
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: data
          }
        ],
        color: this.colors
      }
      if (
        container === 'pieFunction'
      ) {
        option.legend.data = ['市级商圈', '区域商圈', '非商圈区域']
      } else if (container === 'pieDevelop') {
        option.legend.data = ['成熟商圈', '新兴商圈', '非商圈区域']
      }
      charts.setOption(option)
      // charts.dispatchAction({
      //   type: 'highlight',
      //   seriesIndex: 0,
      //   name: maxItem.name
      // })
    },
    getDataVolumes () {
      this.axios.post(api.listFloorArea, { brandId: this.brandId }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.volumesOptions = resData.map(item => {
            const tempObj = {
              label: item.areaName,
              value: item.areaId
            }
            return tempObj
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataPlaza () {
      const params = {
        brand_id: this.brandId
      }
      this.axios.post(api.getBrandShoppingCenterLevelPreference, params).then(res => {
        if (res.data.code === 0 && res.data.data.length) {
          this.hasDataPieLevel = true
          const resData = res.data.data
          let count = 0
          for (let i = 0; i < resData.length; i++) {
            count += resData[i].counts
          }
          if (count === 0) {
            this.hasDataPieLevel = false
          } else {
            this.createChartsPie(this.pieHandleData(resData), 'pieLevel', 2)
          }
        } else {
          this.hasDataPieLevel = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.post(api.getBrandChartAnalysis, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data.brandgouwuguimo.map(item => {
            item.name = item.mianjiname
            return item
          })
          if (resData.length) {
            this.hasDataPieVolumes = true
          } else {
            this.hasDataPieVolumes = false
          }
          const resData1 = res.data.data.brandgouwufloor.map(item => {
            item.name = item.floorName
            return item
          })
          if (resData1.length) {
            this.hasDataPieFloors = true
          } else {
            this.hasDataPieFloors = false
          }
          this.createChartsPie(this.pieHandleData(resData), 'pieVolumes', 3)
          this.createChartsPie(this.pieHandleData(resData1), 'pieFloors', 5)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.post(api.getBrandProjectYear, params).then(res => {
        if (res.data.code === 0 && res.data.data.length) {
          this.hasDataPieYears = true
          const resData = res.data.data
          this.createChartsPie(this.pieHandleData(resData), 'pieYears', 4)
        } else {
          this.hasDataPieYears = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataFloorFormat () {
      const params = {
        brandId: this.brandId,
        areaId: this.volumesNum,
        levelId: '',
        floorId: this.floorId,
        projectId: this.filtroProject
      }
      this.axios.post(api.getBrandIndustryTop10, params).then(res => {
        const extraOption = {
          xAxis: {
            axisLabel: {
              rotate: 45
            }
          }
        }
        this.top10Loading = true
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData.industryTop10 && resData.industryTop10.length > 0) {
            const yData = []
            const xData = []
            const colorList = []
            this.leftPercentage = 0
            this.rightPercentage = 0
            for (let i = 0; i < resData.industryTop10.length; i++) {
              const element = resData.industryTop10[i]
              if (resData.industryTop10[i].intustryType) {
                colorList.push('rgb(255, 206, 115)')
                this.leftPercentage = this.leftPercentage += 1
              } else {
                colorList.push('#3E8CFF')
              }
              xData.push(element.name)
              yData.push(element.percents)
            }
            this.leftPercentage = (this.leftPercentage / resData.industryTop10.length) * 100
            this.createChartsBar([yData, xData], 'barFormat', extraOption, colorList)
          } else {
            this.hasDataFormatTop10 = false
          }
          if (resData.brandTop10 && resData.brandTop10.length > 0) {
            this.brandTopList = resData.brandTop10
            for (let j = 0; j < resData.brandTop10.length; j++) {
              if (resData.brandTop10[j].brandType) {
                this.rightPercentage = this.rightPercentage += 1
              }
            }
            this.rightPercentage = (this.rightPercentage / resData.brandTop10.length) * 100
          } else {
            this.brandTopList = []
            this.hasDataBrandTop10 = false
          }
          this.top10Loading = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    createChartsBar ([yData, xData], container, extraOption, colorList) {
      const charts = echarts.init(document.getElementById(container))
      const option = {
        color: colorList,
        tooltip: {
          trigger: 'axis',
          formatter: '<span style="color: #808191;">{b} : </span>{c} %',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        grid: {
          left: '0%',
          right: '4%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              color: 'rgba(128, 129, 145, 1)'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              interval: 0,
              color: 'rgba(128, 129, 145, 1)',
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            }
          }
        ],
        series: [
          {
            name: '本项目处于',
            type: 'bar',
            barWidth: '30%',
            label: {
              show: false,
              position: 'top',
              color: 'rgba(226,226,226,0.1)',
              formatter: '{c}%'
            },
            itemStyle: {
              normal: {
                color: (params) => {
                  return colorList[params.dataIndex]
                }
              }
            },
            data: yData
          }
        ]
      }
      charts.setOption(option)
      if (extraOption) {
        charts.setOption(extraOption)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.select-extra>>>
  background #373A43
  .el-input__inner
    box-sizing border-box
    width 140px
    height 34px
    border 1px solid #373A43
    color #808191
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.wrap-view
  margin-right 24px
  display flex
  height 364px
  .view1
    background #272930
    width 600px
    opacity 1
    border-radius 2px
  .view2
    margin-left 14px
    background #272930
    width 1078px
    opacity 1
    border-radius 2px
.view3
  margin-right 24px
  margin-top 16px
  // height 700px
  background #272930
  opacity 1
  border-radius 2px
  position relative
  .chartsLine
    width 1280px
    height 338px
  .chartsPie
    width 98%
    height 450px
  .chartsBar
    width 99%
    height 470px
  .simpleWrap
    // width 97%
    margin 35px auto
    overflow hidden
  .mg-0-auto
    margin 0 auto
    margin-top 30px
  .chartsFiltrate
    position relative
    bottom 30px
    right 20px
  .cardbox
    width 32%
    // height 548px
    background #272930
    // border 1px solid rgba(255, 255, 255, 0.1)
    // box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
    border-radius 3px
    box-sizing border-box
    padding 24px 16px
    position relative
    h3
      font-size 16px
      text-align left
    .text
      width auto
      padding-right 20px
      display inline-block
      height 26px
      line-height 26px
      background rgba(255, 205, 147, 0.2)
      border-radius 3px
      position relative
      bottom 60px
      .icon-contrast
        margin 0 3px 0 10px
  .charts-bar
    width 68%
// 图表
.chart-view
  display flex
  .chart-box
    // border 1px solid #f00
    height 330px
    width 50%
.jurisdiction-wrap-no-data
  text-align center
  height 270px
  width 50%
  padding-bottom 24px
  img
    margin-top 50px
    width 200px
    height 144px
  span
    font-size 12px
    color #fff
.brand-ranking
  margin-top 20px
  border-top 1px solid #373A43
  border-left 1px solid #373A43
  border-right 1px solid #373A43
  ul
    li
      height 40px
      line-height 40px
      color #808191
      font-size 14px
      border-bottom 1px solid #373A43
      font-weight 400
      &.active
        background-color rgba(192, 223, 254, 0.16)
        color rgb(255, 206, 115)
        font-weight 800
      span:nth-child(1)
        display inline-block
        width 25%
        padding-left 24px
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      span:nth-child(2)
        display inline-block
        width 32%
        text-align left
        // cursor pointer
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      span:nth-child(3)
        display inline-block
        width 32%
        text-align left
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
.w-select-screen-border
  position absolute
  right 16px
  top 20px
  z-index 111
.tips-wrap
  margin-top 24px
  text-align center
  background linear-gradient(to right, rgba(62, 140, 255, 0.01) 25%, rgba(62, 140, 255, 0.15) 50%, rgba(62, 140, 255, 0.01) 75%)
  p
    height 32px
    line-height 32px
    color #fff
    font-size 14px
.no-data-panel
  height 300px
  overflow hidden
  margin 0
  background #272930
  padding 180px
.menu span
  color #B2B3BD
.cardbox>h3
  font-weight bold
.view3 .w-title-gradients
  width 480px
  span
    color #FFCE73
    font-size 12px
    margin-left 7px
</style>
